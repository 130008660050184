import "core-js/modules/es.date.now";import "core-js/modules/es.date.to-string";import "core-js/modules/es.function.name"; //
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    url: {
      type: String,
      "default": undefined },

    fileType: {
      type: String },

    name: {
      type: String } },


  computed: {
    fileName: function fileName() {
      return (this.name || "\u6587\u4EF6".concat(Date.now())) + ".".concat(this.fileType);
    },
    isMs: function isMs() {
      return (
        this.fileType === 'doc' ||
        this.fileType === 'docx' ||
        this.fileType === 'xls' ||
        this.fileType === 'xlsx');

    },
    isA: function isA() {
      return (
        this.fileType === 'pdf' ||
        this.fileType === 'jpg' ||
        this.fileType === 'png' ||
        this.fileType === 'jpeg');

    } },

  methods: {
    handleReview: function handleReview() {
      if (this.url) {
        switch (this.fileType) {
          case 'doc':
          case 'docx':
          case 'xls':
          case 'xlsx':
            window.open('https://view.officeapps.live.com/op/view.aspx?src=' + this.url);
            break;
          default:
            this.handleWarning('该文件不支持预览，请下载后查看');
            break;}

      }
    } } };