var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isMS
    ? _c(
        "el-button",
        _vm._b(
          { on: { click: _vm.handleReview } },
          "el-button",
          _vm.$attrs,
          false
        ),
        [_vm._t("default", [_vm._v("预览")])],
        2
      )
    : _c(
        "a",
        {
          staticClass: "color-light el-button--text margin-m-right",
          attrs: { target: "blank", href: _vm.url, download: _vm.fileName }
        },
        [_vm._t("default", [_vm._v("预览")])],
        2
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }